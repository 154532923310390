@use "./_fonts";
@use "./_borders";
@use "./_colors";
@use "./_components";

.react-flow__handle  {
    background-color: black;

    border-radius: 0;
    border: none;

    width: 100%;
    height: 8px;

    &:hover {
        background-color: colors.$blue;
    }
}

$handle-offset: -9px;

.react-flow__handle-top {
    top: $handle-offset;
}

.react-flow__handle-bottom {
    bottom: $handle-offset;
}

.react-flow__handle-connecting {
    background-color: colors.$red;
}


.react-flow__handle-valid {
    background-color: colors.$green;
    border-color: colors.$green;
    cursor: cell;
}

.react-flow__node {
    @include fonts.body-regular();

    color: black;

    background-color: white;

    &:not(.selected) > .ludo-node:not(.editing):hover {
        border-color: colors.$blue;
    }
  
    &.selected > .ludo-node {
        border-color: colors.$active;
    }
}

.ludo-node {
    padding: 25px;

    border: borders.$black;
    border-width: 6px;

    .button-panel.node {
        display: flex;
        justify-content: flex-end;
        
        border-bottom: borders.$subtle;
        
        padding-bottom: 12px;
        margin-bottom: 6px;
    }

    &.editing {
        border-style: double;
        cursor: auto;
    }
}

.react-flow__edge-path, .react-flow__connection-path {
    stroke: black;
    stroke-width: 3px;

    cursor: pointer;

    &:hover {
        stroke: colors.$blue;
    }
}

.react-flow__edge {
    &.selected .react-flow__edge-path {
        stroke: colors.$active;
    }
}

.react-flow__connection-path {
    stroke-width: 1px;
    stroke-dasharray: 5;
    animation: connection-dash 5s linear;
}

@keyframes connection-dash {
    from {
      stroke-dashoffset: 100;
    }
}

/** Node Attributes **/
.node-attributes { 
    background-color: white;

    .button-panel.viewing {
        display: none;
    }

    &.editing {
        .button-panel.viewing {
            display: flex;
        }

        .node-attribute.empty {
            cursor: default;
        }
    }
}

.node-attribute {
    display: flex;
    justify-content: flex-start;

    padding-top: 20px;
    min-height: 35px;
    
    $title-width: 100px;
    $value-width: 450px;

    min-width: $title-width + $value-width;


    .attribute-title.display, .attribute-title.editor > input {
        @include fonts.body-bold();
    }

    .attribute-title, .attribute-title.editor > input {
        width: $title-width;
    }

    .attribute-text, .attribute-text.editor > textarea,
    .attribute-number {
        width: $value-width;
        max-width: 100%;
    }

    .attribute-title, .attribute-text {
        word-break: break-word;
    }
    
    .attribute-text {
        white-space: pre-wrap;
    }

    .attribute-text.editor > textarea {
        resize: vertical;
        margin-top: 2px;
    }


    &.editor {
        padding-top: 0px;

        form {
            display: flex;
            justify-content: space-between;

            label {
                font-size: 0.8rem;
                display: block;
            } 
        }

        input, textarea {
            font-size: 1.0rem;
            border: borders.$blue;

            &:focus {
                outline: none;
                border: borders.$active;
            }
        }

        .button-panel {
            padding-top: 20px;
        }
    }

    &.empty {
        @include components.unselectable();
        
        justify-content: center;
        color: colors.$disabled;

        padding-bottom: 20px;
    }

    .button-panel {
        align-self: flex-start;
    }
}

.button-panel {
    display: flex;
    
    &.viewing, &.editing {
        justify-content: flex-end;
    
        margin-left: 20px;
        min-width: 110px;
    }

    &.adding {
        justify-content: center;
        padding-top: 30px;

        min-width: 110px;
    }
}

.node-attribute-button {
    background: none;
    border: none;
    padding: 0;

    width: 32px;
    height: 32px;

    &.add-text, &.add-number, &.edit, &.confirm {
        margin-right: 5px;
    }

    &.cancel {
        margin-right: 10px;
    }
}