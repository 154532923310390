@use './_colors';

$black: 3px solid black;
$blue: 3px solid colors.$blue;
$active: 3px solid colors.$active;
$green: 3px solid colors.$green;
$red: 3px solid colors.$red;
$disabled: 3px solid colors.$disabled;
$subtle: 3px solid colors.$subtle;
$white: 3px solid white;
$blank: 3px solid transparent;