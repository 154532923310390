@use './_fonts';
@use './_components';
@use './_borders';
@use './_colors';
@use './_widths';

$frame-z: 50;

/** Default Tags **/
html {
    scroll-behavior: smooth;
}

body {
    @include fonts.body-regular();
    
    background-color: colors.$background;

    color: white;
}

a {
    color: white;

    &:visited {
        color: white;
    }

    &:hover {
        color: colors.$blue;
    }

    &:active {
        color: colors.$blue-active-outline;
    }
}

h1, h2, h3, h4 {
    @include fonts.header-medium();
    margin-block-end: 0;
}

header {
    @include components.unselectable();

    position: fixed;
    background-color: colors.$background;
    z-index: $frame-z;

    height: widths.$frame-v;
    width: calc(100% - #{widths.$frame-h * 2});

    left: widths.$frame-h;

    padding: 5px 0;
}

.frame {
    position: fixed;
    width: widths.$frame-h;

    height: 100%;
    
    top: 0;

    z-index: $frame-z;

    background-color: colors.$background;
    
    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}

main {
    height: 100vh;
}

footer {
    @include components.unselectable();

    position: fixed;
    
    z-index: $frame-z;
    background-color: colors.$background;
    bottom: 0px;

    width: calc(100% - #{widths.$frame-h * 2});

    left: widths.$frame-h;

    height: widths.$frame-v;
    
    display: flex;
    
    #footer-text {
        @include fonts.header-medium();
        flex-grow: 1;

        font-size:1.0rem;
    }

    #footer-links {
        @include fonts.header-bold();

        font-size: 0.8rem;

        a {
            margin-left: 15px;
        }
    }

       
    @media (max-width: widths.$break-max-screen-third) {
        #footer-text {
            font-size: 0.9rem;
        }

        #footer-links {
            font-size: 0.7rem;
        }
    }

    @media (max-width: widths.$break-max-phone-vertical) {
        #footer-text {
            font-size: 0.8rem;
        }

        #footer-links {
            font-size: 0.6rem;
        }

    }
}

/** Nav **/

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    text-transform: lowercase;
    white-space: nowrap;

    .nav-menu {
        border-left: borders.$green;
        margin-right: 15px;
        
        flex-grow: 1;
        
        font-size: 1.2rem;
    }

    .user-menu {
        border-left: borders.$green;

        font-size: 0.8rem;
    }
    
    @media (max-width: widths.$break-max-screen-third) {
        .nav-menu {
            font-size: 1.0rem;
        }
        .user-menu {
            font-size: 0.8rem;
        }
    }

    @media (max-width: widths.$break-max-phone-vertical) {
        .nav-menu {
            font-size: 0.8rem;
        }

        .user-menu {
            font-size: 0.6rem;
        }
    }

    .social-icon {
        color: white;

        &:hover {
            color: white;
            border-bottom: borders.$white;
        }
    }
}

.nav-link {
    @include fonts.header-bold();   

    text-decoration: none;
    color: white;

    padding: 5px;
    

    margin-left: 5px;
    
    &:hover {
        color: colors.$blue;
    }

    &:active {
        color: colors.$blue-active-outline;
    }

}

#site-logo {
    margin-right: 10px;
    width: 200px;

    svg {
        display: block;

        #site-logo-ludo {
            fill: white;
        }

        #site-logo-systems {
            fill: colors.$green;
        }
    }
    
    &:hover {
        svg {
            #site-logo-ludo {
                fill: colors.$blue;
            } 
            
            #site-logo-systems {
                fill: colors.$green-hover-outline;
            }                
        }
    }

    &:active {
        svg {
            #site-logo-ludo{
                fill: colors.$blue-active-outline;
            }

            #site-logo-systems {
                fill: colors.$green-active-outline;
            }
        }
    }

    @media (max-width: widths.$break-max-phone-vertical) {
        width: 120px;
    }
}

/** Pages **/

.page-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: colors.$background-opaque;

    $padding: widths.$frame-v + 50px;   

    padding-top: $padding;
    padding-bottom: $padding;

    min-height: calc(100% - #{$padding} * 2);
    
    h1 {
        margin-bottom: 30px;
    }

    .content {
        max-width: 80%;
        border: borders.$green;
        padding: 30px;

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &.home {

        img {
            width: 100%;
        }

        .content {
            min-width: 80%;
            p, ul, li {
                @include widths.centered(widths.$content-default);
                max-width: 95%;

                font-size: 1.25rem;

                line-height: 1.5;

                &.disclaimer {
                    padding: 18px;
                    text-align: center;
                    border: borders.$red;

                    .warning-text {
                        color: colors.$red;
                    }
                }
            }
        }

        .video {
            max-width: 100%;
        }
    }
}

.auth {
    form {      
        width: 200px;

        .error, .alert {
            align-self: center;

            margin-top: 2px;  
            
            font-size: 0.9rem;

            text-decoration: underline;
            text-decoration-color: colors.$red;
        }

        .form-row {           
            display: flex;
            flex-direction: column;

            label {
                @include fonts.header-medium();
            }
         
            input {
                border: borders.$blue;
                outline: none;

                font-size: 0.8rem;

                &:focus {
                    border: borders.$active;
                }
            }

            &:not(:first-child) {
                margin-top: 10px;
            }
        }
    
        button {
            @include components.button();
            align-self: center;
    
            margin-top: 10px;
            width: 100px;
        }
    }
}

/** Nodes **/

#node-editor {
    height: 100%;
    
    background-color: #EFEFEF;
}

.warning {
    @include fonts.body-regular();
    position: absolute;

    z-index: 10;

    left: 5%;
    right: 5%;

    padding: 10px;
    margin-top: widths.$frame-v + 10px;

    border: borders.$black;
    border-left: borders.$black;
    border-right: borders.$black;
    border-bottom: borders.$black;
    
    background-color: red;
    color: white;

    div {
        margin: 10px;
    }
}

.react-flow__minimap {
    margin-right: widths.$frame-h;
    margin-bottom: widths.$frame-v;
    
    @media (max-width: widths.$break-max-phone-vertical) {
        width: 150px;
        height: 100px;
    }
}

.react-flow__controls {
    margin-left: widths.$frame-h;
    margin-bottom: widths.$frame-v;
}

#node-editor-controls {
    position: absolute;

    margin-top: widths.$frame-v;
    margin-left: widths.$frame-h;

    z-index: 5;
    padding: 15px;    

    display: flex;

    flex-direction: column;

    .control-group {
        position: relative;
        margin-top: 10px;
        padding: 15px;
        border: borders.$black;
        
        &:first-child {
            border-top-right-radius: 25px;
        }

        &:last-child {
            border-bottom-right-radius: 25px;
        }

        .label {
            @include fonts.header-medium();
            @include components.unselectable();

            cursor: default;

            font-size: 0.8rem;

            position: absolute;
            top: 0px;
            left: 0px;

            padding-left: 2px;
            padding-right: 6px;
            padding-bottom: 2px;

            background-color: black;
        }

        @media (max-width: widths.$break-max-screen-third) {
            padding: 5px;
        }
    }

    .node-editor-control {
        background: none;
        border: none;
        padding: 0;
    
        width: 60px;

        &:not(:first-child) {
            margin-top: 20px;
        }

        @media (max-width: widths.$break-max-phone-vertical) {
            width: 50px;
        }
    }

    #node-add-button {
        &:hover {
            svg {
                .interior-background {
                    fill: colors.$green-hover-fill;
                }
                .create-button {
                    fill: colors.$green-hover-outline;
                }
            }
        }
    
        &:active {
            svg { 
                .interior-background {
                    fill: colors.$green-active-fill;
                }
    
                .create-button {
                    fill: colors.$green-active-outline;
                }
            }
        }
    }

    #export-json-button {
        &:hover {
            svg {
                .interior-background {
                    fill: colors.$blue-hover-fill;
                }

                .change-button {
                    fill: colors.$blue-hover-outline;
                }
            }
        }

        &:active {
            svg {
                .interior-background {
                    fill: colors.$blue-active-fill;
                }

                .change-button {
                    fill: colors.$blue-active-outline;
                }
            }
        }
    }
}

/** UI Buttons **/

.ui-button {
    cursor: pointer;

    .interior-background {
        fill: white;
    }
}

.create-button {
    fill: colors.$green;

    &:hover {
        fill: colors.$green-hover-outline;

        .interior-background {
            fill: colors.$green-hover-fill; 
        }
    }

    &:active {
        fill: colors.$green-active-outline;

        .interior-background {
            fill: colors.$green-active-fill;
        }
    }
}

.change-button {
    fill: colors.$blue;
    
    &:hover {
        fill: colors.$blue-hover-outline;

        .interior-background {
            fill: colors.$blue-hover-fill;
        }
    }

    &:active {
        fill: colors.$blue-active-outline;

        .interior-background {
            fill: colors.$blue-active-fill;
        }
    }
}

.remove-button {
    fill: colors.$red;

    &:hover {
        fill: colors.$red-hover-outline;

        .interior-background {
            fill: colors.$red-hover-fill; 
        }
    }

    &:active {
        fill: colors.$red-active-outline;

        .interior-background {
            fill: colors.$red-active-fill; 
        }
    }
}

.disabled > svg {
    cursor: not-allowed;
    fill: colors.$disabled;

    &:hover {
        fill: colors.$disabled;
        
        .interior-background {
            fill: white;
        }
    }
    &:active {
        fill: colors.$disabled;

        .interior-background {
            fill: white;
        }
    }
}