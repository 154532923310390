$break-max-phone-vertical: 500px;
$break-max-screen-third: 750px;

$content-default: 650px;

$frame-v: 30px;
$frame-h: 15px;

/** Other mixins **/
@mixin centered($content-width) {
    width: $content-width;
    margin-left: auto;
    margin-right: auto;
}

@mixin default() {
    @include centered(95%);
    max-width: 95%;
}