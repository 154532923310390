@use './_fonts';
@use './_borders';
@use './_colors';

@mixin unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin button {
    @include fonts.body-bold();
    @include unselectable();

    display: inline-block;
    margin: 2px; 

    font-size: 0.8rem;

    text-decoration: none;

    border-radius: 15px;
    border: borders.$blank;

    background-color: colors.$blue;

    cursor: pointer;

    
    &:hover {
        // border-color: colors.$active;
        background-color: lighten(colors.$blue, 10%); 
        
    }

    &:active {
        // border-color: darken(colors.$blue, 20%);

        background-color: colors.$active;
        
    }

    &:disabled {
        background-color: #AAA;
        cursor: not-allowed;
        
        &:hover {
            background-color: #AAA;
        }
    }


}